import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormGroup from "@material-ui/core/FormGroup";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../assets/bannerImage.png";
import success from "../assets/success.png";
import close from "../assets/close.png";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
  root: {
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {
  //reference for scrolling
  const myRef = useRef(null);

  const [country, setCountry] = React.useState(
    process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76" ||
      process.env.REACT_APP_LAB_ID === "80" ||
      process.env.REACT_APP_LAB_ID === "81" ||
      process.env.REACT_APP_LAB_ID === "82" ||
      process.env.REACT_APP_LAB_ID === "114" ||
      process.env.REACT_APP_LAB_ID === "115" ||
      process.env.REACT_APP_LAB_ID === "118" ||
      process.env.REACT_APP_LAB_ID === "127"
      ? "GB"
      : "US"
  );
  const [countryList, setCountryList] = React.useState([]);

  //State for race
  const [raced, setRaced] = React.useState([]);
  //State for Ethnicity
  const [RaceEthinicity, SetRaceEthinicity] = React.useState([]);
  //loading state
  const [isLoaded, setIsLoaded] = React.useState(false);

  //confirmation code state
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [getQRcode, setQrcode] = React.useState("");
  const [getpdfdownload, setpdfdownload] = React.useState("");
  const [labSiteName, setlabSiteName] = React.useState("");
  const [dob, setdob] = React.useState("");
  const [getdate, setdate] = React.useState("");
  const [gettime, settime] = React.useState("");
  //Temporary state for counties
  const [countyTemp, setCountyTemp] = React.useState([]);

  const [stateSelect, setstateSelect] = React.useState([]);

  //
  // Covid vaccine type state and OnChange
  const [Covid19vaccineType, setCovid19vaccineType] = React.useState("");
  const [Covid19vaccineTypeError, setCovid19vaccineTypeError] =
    React.useState("");

  const handleCovid19vaccineType = (event) => {
    setCovid19vaccineType(event.target.value);
  };

  //Dose Count State and OnChange
  const [doseCount, setdoseCount] = React.useState("");
  const [doseCountError, setdoseCountError] = React.useState("");
  const handleChangedoseCount = (event) => {
    setdoseCount(event.target.value);
  };

  //last dose date state and OnChange
  const [lastDoseDate, setlastDoseDate] = React.useState(null);
  const [lastDoseDateError, setlastDoseDateError] = React.useState(null);

  const handlelastDoseDate = (date) => {
    setlastDoseDate(date);
  };

  // ImmigrationStatus state and Handle Change
  const [immigrationStatus, setimmigrationStatus] = React.useState("");
  const [immigrationStatusError, setimmigrationStatusError] =
    React.useState("");
  const immigrationStatusChange = (event) => {
    setimmigrationStatus(event.target.value);
  };

  const [testType, settestType] = React.useState("");
  const handletestType = (event) => {
    settestType(event.target.value);
  };
  const [hivtest, sethivtest] = React.useState("");
  const handlehivtest = (event) => {
    sethivtest(event.target.value);
  };
  const [HeardofPrep, setHeardofPrep] = React.useState("");
  const handleHeardofPrep = (event) => {
    setHeardofPrep(event.target.value);
  };
  const [CurrentonPrep, setCurrentonPrep] = React.useState("");
  const handleCurrentonPrep = (event) => {
    setCurrentonPrep(event.target.value);
  };
  const [PrepinLast, setPrepinLast] = React.useState("");
  const handlePrepinLast = (event) => {
    setPrepinLast(event.target.value);
  };
  const [sexWithMale, setsexWithMale] = React.useState("");
  const handlesexWithMale = (event) => {
    setsexWithMale(event.target.value);
  };
  const [sexWithFemale, setsexWithFemale] = React.useState("");
  const handlesexWithFemale = (event) => {
    setsexWithFemale(event.target.value);
  };
  const [sexWithTransgender, setsexWithTransgender] = React.useState("");
  const handlesexWithTransgender = (event) => {
    setsexWithTransgender(event.target.value);
  };
  const [injectionDrugUse, setinjectionDrugUse] = React.useState("");
  const handleinjectionDrugUse = (event) => {
    setinjectionDrugUse(event.target.value);
  };
  const [authName, setauthName] = React.useState("");
  const handleAuthNameChange = (event) => {
    setauthName(event.target.value);
  };

  const [consentName, setconsentName] = React.useState("");
  const handleConsentNameChange = (event) => {
    setconsentName(event.target.value);
  };

  //======Get CountryList======//
  const GetCountryList = () => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/bvi/country/list", {})
      .then((response) => {
        if (response) {
          setCountryList(response.data.info);
          // console.log(response.data.info);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  //======Get State List======//
  const GetStateList = () => {
    let data = {
      countryId:
        process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127"
          ? "232"
          : "233",
    };

    axios
      .post(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/bvi/state/list" +
          "?countryId=" +
          data.countryId
      )
      .then((response) => {
        if (response) {
          setformState({
            ...formstate,
            ["city"]: "",
          });
          setCounty("");
          let allEntries = Object.entries(response.data.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setstateSelect(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  //======Get CountyList======//
  const GetCountyList = () => {
    let data = {
      stateId:
        process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127"
          ? "4967"
          : "4966",
    };

    axios
      .post(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/bvi/city/list" +
          "?stateId=" +
          data.stateId
      )
      .then((response) => {
        if (response) {
          let allEntries = Object.entries(response.data.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setCountyTemp(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  useEffect(() => {
    GetCountryList();
    GetStateList();
    GetCountyList();
    axios
      .get(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/web/race/ethinicity/list" +
          "?labId=" +
          process.env.REACT_APP_LAB_ID,
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
          },
        }
      )
      .then((res) => {
        setRaced(res.data.info.races.filter( r => r.raceName !== 'Nothing chosen' ));
        SetRaceEthinicity(res.data.info.ethinicitys.filter( e => e.ethinicityName !== 'Unknown' ));
      });
  }, []);

  // api calling for Race
  const getRaces = () => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
        },
      })
      .then((res) => {
        setCountyTemp(
          res.data.info.counties[
            process.env.REACT_APP_LAB_ID === "71" ||
            process.env.REACT_APP_LAB_ID === "72" ||
            process.env.REACT_APP_LAB_ID === "73" ||
            process.env.REACT_APP_LAB_ID === "74" ||
            process.env.REACT_APP_LAB_ID === "75" ||
            process.env.REACT_APP_LAB_ID === "76" ||
            process.env.REACT_APP_LAB_ID === "80" ||
            process.env.REACT_APP_LAB_ID === "81" ||
            process.env.REACT_APP_LAB_ID === "82" ||
            process.env.REACT_APP_LAB_ID === "114" ||
            process.env.REACT_APP_LAB_ID === "115" ||
            process.env.REACT_APP_LAB_ID === "118" ||
            process.env.REACT_APP_LAB_ID === "127"
              ? "British Virgin Islands"
              : "Virgin Islands"
          ]
        );
        setstateSelect(Object.entries(res.data.info.states));
      });
  };

  const classes = useStyles();

  //Form Initialization
  const initialFormData = Object.freeze({
    //first accordion
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    zipCode: "",

    //second accordion

    occupation: "",
  });

  // form state

  const [formstate, setformState] = React.useState(initialFormData);

  //Panel state - Accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //move panel
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);

  //symptom date state and Handle change
  const [selectedDateSymptom, setSelectedDateSymptom] = React.useState(null);

  const handleDateChangeSymptom = (date) => {
    setSelectedDateSymptom(date);
  };

  //checkbox symptom initialization

  const initialFormData1 = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Headache: false,
    Difficulty_breathing: false,
    Fatigue: false,
    Diarrhea: false,
    Vomiting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
    Body_and_or_muscle_aches: false,
    Congestion_and_or_runny_nose: false,
  });

  //Checkbox symptom state and Handle Change

  const [stateChecked, setStateCheck] = React.useState(initialFormData1);

  const handleChangeCheck = (event) => {
    setStateCheck({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  // pregnancy radio state and handle change
  const [pregnancy, setPregnancy] = React.useState("U");
  const handleChangeFormPregnancy = (event) => {
    setPregnancy(event.target.value);
  };

  const [symptomatic, setSymptomatic] = React.useState("");
  const handleSymptomatic = (event) => {
    setSymptomatic(event.target.value);

    if (event.target.value === "N") {
      setSelectedDateSymptom(null);
      setStateCheck(initialFormData1);
    }
  };

  const [vaccinated, setVaccinated] = React.useState("");
  const handleVaccinated = (event) => {
    setVaccinated(event.target.value);

    if (event.target.value === "N") {
      setCovid19vaccineType("");
      setdoseCount("");
      setlastDoseDate(null);
    }
  };

  const moveNext = () => {
    updateErrorTestType("");
    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCountry("");
    updateCity("");
    updateCountry("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateBirthGender("");
    updateState("");
    updateDateofBirth("");
    updateSymptomDate("");
    updateSymptoms("");
    seterrorMember("");
    updateErrorEthnicity("");
    updateErrorSymptomatic("");
    updateErrorVaccinated("");
    updatehivtest("");
    updateHeardofPrep("");
    updateCurrentonPrep("");
    updatePrepinLast("");
    updatesexWithMale("");
    updatesexWithFemale("");
    updatesexWithTransgender("");
    updateinjectionDrugUse("");

    if (
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17") &&
      testType !== ""
    ) {
      updateErrorTestType("");
    }
    if (formstate.firstName !== "") {
      updateFirstErrorData("");
    }

    if (formstate.lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (formstate.email.trim() !== "") {
      updateEmailErrorData("");
    }

    if (formstate.addressOne !== "") {
      updateAddress("");
    }

    if (country !== "") {
      updateCountry("");
    }
    if (county !== "") {
      updateCounty("");
    }
    if (formstate.city !== "") {
      updateCity("");
    }

    if (formstate.zipCode !== "") {
      updateZip("");
    }

    if (state !== "") {
      updateState("");
    }
    if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127") &&
      (immigrationStatus !== null || immigrationStatus !== "")
    ) {
      setimmigrationStatusError("");
    }
    if (stateCheckedNA !== "") {
      updateEmailErrorData("");
    }

    //validations
    if (
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17") &&
      testType === ""
    ) {
      updateErrorTestType("please select the test type");
      myRef.current.scrollIntoView();
    } else if (
      formstate.firstName.trim() == "" &&
      formstate.firstName.trim().length == 0
    ) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (
      formstate.lastName.trim() == "" &&
      formstate.lastName.trim().length == 0
    ) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      (stateCheckedNA === false &&
        formstate.email.trim() == "" &&
        formstate.email.trim().length == 0) ||
      stateCheckedNA === ""
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email.trim()
      ) &&
      formstate.email.trim() != ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    } else if (
      formstate.addressOne.trim() == "" &&
      formstate.addressOne.trim().length == 0
    ) {
      updateAddress("Please enter address");
      myRef.current.scrollIntoView();
    } else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim() === "" &&
      formstate.zipCode.trim().length === 0
    ) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim().length < 5 ||
      formstate.zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    } else if (
      countyTemp.length &&
      county.trim() == "" &&
      county.trim().length == 0
    ) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      !countyTemp.length &&
      formstate.city.trim() == "" &&
      formstate.city.trim().length == 0
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
    } else if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127") &&
      (immigrationStatus == null || immigrationStatus == "")
    ) {
      setimmigrationStatusError("Please select the immigration status");
      myRef.current.scrollIntoView();
    } else {
      setExpanded("panel2");
      setActive1(true);

      myRef.current.scrollIntoView();
    }
  };

  //move next panel

  const moveNext1 = () => {
    updateErrorTestType("");
    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCity("");
    updateCountry("");
    updateCountry("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateBirthGender("");
    updateState("");
    updateDateofBirth("");
    updateSymptomDate("");
    updateSymptoms("");
    seterrorMember("");
    updateErrorEthnicity("");
    updateErrorSymptomatic("");
    updateErrorVaccinated("");
    updatehivtest("");
    updateHeardofPrep("");
    updateCurrentonPrep("");
    updatePrepinLast("");
    updatesexWithMale("");
    updatesexWithFemale("");
    updatesexWithTransgender("");
    updateinjectionDrugUse("");
    console.log(
      moment(selectedDate).format("MM/DD/yyyy"),
      moment(new Date()).format("MM/DD/yyyy")
    );
    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirth("");
      myRef.current.scrollIntoView();
      // return;
    }
    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }

    if (
      birthgender !== "" &&
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17")
    ) {
      updateBirthGender("");
    }

    if (member !== undefined) {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    //validations
    if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirth("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      // return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    } else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } else if (
      birthgender === "" &&
      birthgender.length === 0 &&
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17")
    ) {
      updateBirthGender("Please select sex at birth gender");
      myRef.current.scrollIntoView();
    } else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == undefined) {
      seterrorMember("Please select patient group");
      myRef.current.scrollIntoView();
    } else if (member !== "" && formstate.occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
    } else {
      //uncomment
      setExpanded("panel3");

      // setExpanded("panel4");
      setActive2(true);
      myRef.current.scrollIntoView();
    }
  };

  // Whole form Textfield Handle Change event
  const handleChangeForm = (event) => {
    const name = event.target.name;
    let value = event.target.value.replaceAll('"', "'");
    setformState({
      ...formstate,
      [name]: value.replaceAll("''", "'"),
    });
  };

  //Gender state and select Handle Change
  const [gender, setGender] = React.useState("");

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const [birthgender, setbirthgender] = React.useState("");

  const handleChangeBirthGender = (event) => {
    setbirthgender(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setCountyTemp([]);
    countryList.map((item) => {
      if (item.countryCode === event.target.value) {
        let data = {
          countryId: item.id,
        };
        console.log(data);
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT +
              "/api/bvi/state/list" +
              "?countryId=" +
              data.countryId
          )
          .then((response) => {
            if (response) {
              setState("");
              setformState({
                ...formstate,
                ["city"]: "",
              });
              setCounty("");
              let allEntries = Object.entries(response.data.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setstateSelect(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  //state select
  // const [state, setState] = React.useState("");
  const [state, setState] = React.useState(
    process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76" ||
      process.env.REACT_APP_LAB_ID === "80" ||
      process.env.REACT_APP_LAB_ID === "81" ||
      process.env.REACT_APP_LAB_ID === "82" ||
      process.env.REACT_APP_LAB_ID === "114" ||
      process.env.REACT_APP_LAB_ID === "115" ||
      process.env.REACT_APP_LAB_ID === "118" ||
      process.env.REACT_APP_LAB_ID === "127"
      ? "BVI,British Virgin Islands"
      : "VI,Virgin Islands"
  );

  // Load county based on state
  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");
    setState(event.target.value);
    setformState({
      ...formstate,
      ["city"]: "",
    });
    setCounty("");

    stateSelect.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT +
              "/api/bvi/city/list" +
              "?stateId=" +
              data.stateId
          )
          .then((response) => {
            if (response) {
              let allEntries = Object.entries(response.data.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setCountyTemp(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  //County State and Handle Change
  // const [county, setCounty] = React.useState("St. Croix");

  // const [county, setCounty] = React.useState(process.env.REACT_APP_COUNTY);
  const [county, setCounty] = React.useState("");

  const countyChange = (event) => {
    setCounty(event.target.value);
  };

  //Ethnicity radio
  const [ethnicity, setEthnicity] = React.useState("");
  const handleChangeEthnicity = (event) => {
    setEthnicity(event.target.value);
  };

  //memberGroup state and Handle Change
  const [member, setMember] = React.useState();
  const handleChangeMember = (event) => {
    setMember(event.target.value);
  };

  //date of birth date state and Handle change
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //Not Applicable state and Handle Change

  const [stateCheckedNA, setStateCheckNA] = React.useState(false);

  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };
  //send mobile chekcox

  const [localMobileCheck, setlocalMobileCheck] = React.useState(false);

  const handlelocalMobileCheck = (event) => {
    setlocalMobileCheck(event.target.checked);
    setsendMessageToMobileCheck(
      event.target.checked === true
        ? false
        : event.target.checked === false
        ? true
        : null
    );
    if (event.target.checked === true) {
      setNumber("10000000000");
    } else {
      setNumber("1");
    }
  };

  const [sendMessageToMobileCheck, setsendMessageToMobileCheck] =
    React.useState(true);

  const handleChangeMobileCheck = (event) => {
    setsendMessageToMobileCheck(event.target.checked);
  };

  //checkbox secondary insurance

  const [newstateChecked, newsetStateCheck] = React.useState(false);

  const handleChangeCheckInsurance = (event) => {
    newsetStateCheck(event.target.checked);
  };

  //race handle change and state
  const [racedSelect, setRacedSelect] = React.useState("");

  const handleChangeRace = (event) => {
    setRacedSelect(event.target.value);
  };

  //modal box open state
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  //modal box close
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  //modal box body
  const body = (
    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} className="popImage" />
        </div>
        <div className="center">
          <img src={success} className="popImage1" />
        </div>
      </div>

      <div className="successmsg">
        <div style={{ fontSize: "35px", marginTop: "15px" }}>
          Patient registered successfully
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={getQRcode} style={{ width: "160px" }} />
          </div>
          <div style={{ marginTop: "120px" }}>
            <a href={getpdfdownload} target="_blank">
              <i className="fa fa-download"></i>
            </a>
          </div>
        </div>

        <table style={{ display: "inline-grid", paddingBottom: "1.5em" }}>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Confirmation Code:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{confirmationCode}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Name:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {formstate.firstName} {formstate.lastName}
              </div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>DOB:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{dob}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Lab Site:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{labSiteName}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>
                Appointment Created Date and Time(AST):
              </div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {getdate} - {gettime}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  //Error States

  const [errorTestType, updateErrorTestType] = React.useState();
  const [errorFirstData, updateFirstErrorData] = React.useState();
  const [errorLastData, updateLastErrorData] = React.useState();
  const [errorMobileData, updateMobileErrorData] = React.useState();
  const [errorEmailData, updateEmailErrorData] = React.useState();
  const [errorAddress, updateAddress] = React.useState();
  const [errorCity, updateCity] = React.useState();
  const [errorCounty, updateCounty] = React.useState();
  const [errorCountry, updateCountry] = React.useState();
  const [errorZip, updateZip] = React.useState();
  const [errorOccupation, updateOccupation] = React.useState();
  const [errorRace, updateRace] = React.useState();
  const [errorGender, updateGender] = React.useState();
  const [errorBirthGender, updateBirthGender] = React.useState();

  const [errorState, updateState] = React.useState();
  const [errorStateChecked, updateStateChecked] = React.useState();

  const [errorDateofbirth, updateDateofBirth] = React.useState();
  const [errorSymptomDate, updateSymptomDate] = React.useState();
  const [errorSymptoms, updateSymptoms] = React.useState();

  const [errorMember, seterrorMember] = React.useState();
  const [errorEthnicity, updateErrorEthnicity] = React.useState();
  const [errorSymptomatic, updateErrorSymptomatic] = React.useState();
  const [errorVaccinated, updateErrorVaccinated] = React.useState();

  const [errorhivtest, updatehivtest] = React.useState();
  const [errorHeardofPrep, updateHeardofPrep] = React.useState();
  const [errorCurrentonPrep, updateCurrentonPrep] = React.useState();
  const [errorPrepinLast, updatePrepinLast] = React.useState();
  const [errorsexWithMale, updatesexWithMale] = React.useState();
  const [errorsexWithFemale, updatesexWithFemale] = React.useState();
  const [errorsexWithTransgender, updatesexWithTransgender] = React.useState();
  const [errorinjectionDrugUse, updateinjectionDrugUse] = React.useState();

  const formSubmit = (e) => {
    updateErrorTestType("");
    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCounty("");
    updateCity("");
    updateCountry("");
    updateZip("");
    updateRace("");
    updateGender("");
    updateBirthGender(""); 
    updateState("");
    updateDateofBirth("");
    updateSymptomDate("");
    updateSymptoms("");
    seterrorMember("");
    updateErrorEthnicity("");
    updateErrorSymptomatic("");
    updateErrorVaccinated("");
    updatehivtest(""); 
    updateHeardofPrep("");
    updateCurrentonPrep("");
    updatePrepinLast("");
    updatesexWithMale("");
    updatesexWithFemale("");
    updatesexWithTransgender("");
    updateinjectionDrugUse("");

    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirth("");
      myRef.current.scrollIntoView();
      // return;
    }
    if (
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17") &&
      testType !== ""
    ) {
      updateErrorTestType("");
    }
    if (formstate.firstName !== "") {
      updateFirstErrorData("");
    }

    if (formstate.lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (formstate.email.trim() !== "") {
      updateEmailErrorData("");
    }

    if (formstate.addressOne !== "") {
      updateAddress("");
    }

    if (country !== "") {
      updateCountry("");
    }
    if (formstate.city !== "") {
      updateCity("");
    }
    if (formstate.zipCode !== "") {
      updateZip("");
    }

    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }
    if (testType === "HIV" || testType === "Both") {
      if (birthgender !== "") {
        updateBirthGender("");
      }
      if (hivtest !== "") {
        updatehivtest("");
      }
      if (HeardofPrep !== "") {
        updateHeardofPrep("");
      }
      if (CurrentonPrep !== "") {
        updateCurrentonPrep("");
      }
      if (PrepinLast !== "") {
        updatePrepinLast("");
      }
      if (sexWithMale !== "") {
        updatesexWithMale("");
      }
      if (sexWithFemale !== "") {
        updatesexWithFemale("");
      }
      if (sexWithTransgender !== "") {
        updatesexWithTransgender("");
      }
      if (injectionDrugUse !== "") {
        updateinjectionDrugUse("");
      }
    }
    if (state !== "") {
      updateState("");
    }
    if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127") &&
      immigrationStatus !== ""
    ) {
      setimmigrationStatusError("");
    }
    if (selectedDate !== null) {
      updateDateofBirth("");
    }

    if (member !== undefined) {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom !== null
    ) {
      updateSymptomDate("");

      myRef.current.scrollIntoView();
    }

    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
      0
    ) {
      updateSymptoms("");
      myRef.current.scrollIntoView();
    }

    if (symptomatic !== null || symptomatic !== "") {
      updateErrorSymptomatic("");
      myRef.current.scrollIntoView();
    }

    if (vaccinated !== null || vaccinated !== "") {
      updateErrorVaccinated("");
      myRef.current.scrollIntoView();
    }
    if (
      process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76" ||
      process.env.REACT_APP_LAB_ID === "80" ||
      process.env.REACT_APP_LAB_ID === "81" ||
      process.env.REACT_APP_LAB_ID === "82" ||
      process.env.REACT_APP_LAB_ID === "114" ||
      process.env.REACT_APP_LAB_ID === "115" ||
      process.env.REACT_APP_LAB_ID === "118" ||
      process.env.REACT_APP_LAB_ID === "127"
    ) {
      if (
        (vaccinated == "Y" && Covid19vaccineType !== null) ||
        (vaccinated == "Y" && Covid19vaccineType !== "")
      ) {
        setCovid19vaccineTypeError("");
        myRef.current.scrollIntoView();
      }
      if (
        vaccinated == "Y" &&
        Covid19vaccineType !== "Janssen" &&
        doseCount !== ""
      ) {
        setdoseCountError("");
        myRef.current.scrollIntoView();
      }
      if (vaccinated == "Y" && lastDoseDate !== null) {
        setlastDoseDateError("");
        myRef.current.scrollIntoView();
      }
    }
    //validations
    if (
      (process.env.REACT_APP_LAB_ID === "16" ||
        process.env.REACT_APP_LAB_ID === "17") &&
      testType === ""
    ) {
      updateErrorTestType("please select the test type");
      myRef.current.scrollIntoView();
    } else if (formstate.firstName == "" && formstate.firstName.length == 0) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (formstate.lastName == "" && formstate.lastName.length == 0) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      formstate.email.trim() == "" &&
      formstate.email.length == 0
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (formstate.addressOne == "" && formstate.addressOne.length == 0) {
      updateAddress("Please enter address");
      myRef.current.scrollIntoView();
    } else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim() === "" &&
      formstate.zipCode.trim().length === 0
    ) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      formstate.zipCode.trim().length < 5 ||
      formstate.zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    } else if (
      countyTemp.length &&
      county.trim() == "" &&
      county.trim().length == 0
    ) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      !countyTemp.length &&
      formstate.city.trim() == "" &&
      formstate.city.trim().length == 0
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
    } else if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127") &&
      immigrationStatus == ""
    ) {
      setimmigrationStatusError("Please select the immigration status");
      myRef.current.scrollIntoView();
    } else if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirth("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    } else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } else if (
      birthgender === "" &&
      birthgender.length === 0 &&
      testType !== ""
    ) {
      updateBirthGender("Please select sex at birth gender");
      myRef.current.scrollIntoView();
    } else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == undefined) {
      seterrorMember("Please select patient group");
      myRef.current.scrollIntoView();
    } else if (member === "Others" && formstate.occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
      return;
    } else if (symptomatic == null || symptomatic == "") {
      updateErrorSymptomatic(
        "Please select if you experience any Covid -19 symptoms?"
      );
      myRef.current.scrollIntoView();
      return;
    } else if (
      symptomatic === "Y" &&
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
        .length === 0
    ) {
      updateSymptoms("Please select Symptoms");
      myRef.current.scrollIntoView();
      return;
    } else if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom == null
    ) {
      updateSymptomDate("Please select Symptom Date");
      myRef.current.scrollIntoView();
      return;
    } else if (vaccinated == null || vaccinated == "") {
      updateErrorVaccinated(
        "Please select if you received the Covid-19 vaccine?"
      );
      myRef.current.scrollIntoView();
      return;
    } else if ((process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76" ||
        process.env.REACT_APP_LAB_ID === "80" ||
        process.env.REACT_APP_LAB_ID === "81" ||
        process.env.REACT_APP_LAB_ID === "82" ||
        process.env.REACT_APP_LAB_ID === "114" ||
        process.env.REACT_APP_LAB_ID === "115" ||
        process.env.REACT_APP_LAB_ID === "118" ||
        process.env.REACT_APP_LAB_ID === "127") && vaccinated == "Y" && Covid19vaccineType == ""
    ) {
      setCovid19vaccineTypeError("Please select COVID-19 vaccine type ");
      myRef.current.scrollIntoView();
      return;
    } else if ((process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76" ||
      process.env.REACT_APP_LAB_ID === "80" ||
      process.env.REACT_APP_LAB_ID === "81" ||
      process.env.REACT_APP_LAB_ID === "82" ||
      process.env.REACT_APP_LAB_ID === "114" ||
      process.env.REACT_APP_LAB_ID === "115" ||
      process.env.REACT_APP_LAB_ID === "118" ||
      process.env.REACT_APP_LAB_ID === "127") && vaccinated == "Y" && Covid19vaccineType !== "Janssen" && doseCount == "") {
      setdoseCountError("Please select COVID-19 vaccine dose count ");
      myRef.current.scrollIntoView();
      return;
    }
    else if ((process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76" ||
      process.env.REACT_APP_LAB_ID === "80" ||
      process.env.REACT_APP_LAB_ID === "81" ||
      process.env.REACT_APP_LAB_ID === "82" ||
      process.env.REACT_APP_LAB_ID === "114" ||
      process.env.REACT_APP_LAB_ID === "115" ||
      process.env.REACT_APP_LAB_ID === "118" ||
      process.env.REACT_APP_LAB_ID === "127") && vaccinated == "Y" && lastDoseDate == null) {
      setlastDoseDateError("Please select last dose date ");
      myRef.current.scrollIntoView();
      return;
    }
      else if ((testType === "HIV" || testType === "Both") && hivtest === "") {
        updatehivtest("Please select previous HIV test");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && HeardofPrep === "") {
        updateHeardofPrep("Please select Ever heard of prep");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && CurrentonPrep === "") {
        updateCurrentonPrep("Please select current on prep");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && PrepinLast === "") {
        updatePrepinLast("Please select have you used prep in past 12 months");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && sexWithMale === "") {
        updatesexWithMale("Please select have you had sex with a male");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && sexWithFemale === "") {
        updatesexWithFemale("Please select have you had sex with a femmale");
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && sexWithTransgender === "") {
        updatesexWithTransgender(
          "Please select have you had sex with a transgender"
        );
        myRef.current.scrollIntoView();
        return;
      } else if ((testType === "HIV" || testType === "Both") && injectionDrugUse === "") {
        updateinjectionDrugUse(
          "Please select have you injected any drugs or substances"
        );
        myRef.current.scrollIntoView();
        return;
        } else if (
      formstate.firstName !== "" &&
      formstate.lastName !== "" &&
      newnumber !== "" &&
      formstate.addressOne !== "" &&
      // formstate.city !== "" &&
      formstate.zipCode !== "" &&
      // ((process.env.REACT_APP_LAB_ID === "71" || process.env.REACT_APP_LAB_ID === "72" ||process.env.REACT_APP_LAB_ID === "73" ||process.env.REACT_APP_LAB_ID === "74") && immigrationStatus !== "")
      // &&
      member !== undefined &&
      gender !== "" &&
      state !== "" &&
      selectedDate !== null &&
      racedSelect !== "" &&
      ethnicity != "" &&
      symptomatic !== ""
      //  &&
      // ((process.env.REACT_APP_LAB_ID === "71" || process.env.REACT_APP_LAB_ID === "72" ||process.env.REACT_APP_LAB_ID === "73" ||process.env.REACT_APP_LAB_ID === "74") && vaccinated == "Y" && (Covid19vaccineType == "" || (Covid19vaccineType !== "Janssen" && doseCount == "") || lastDoseDate === null))
    ) {
      var finaldateDob = moment(selectedDate).format("yyyy-MM-DD");

      alertbtn(formstate.firstName.trim(),formstate.lastName.trim(),finaldateDob,newnumber,formstate.email);

      
    }
  };

  //===Pop Up modal functionalities===//
  const alertbtn = (Fname, Lname, DOB, Phone, Email) => {
    confirmAlert({
      title: 'Please confirm your details',
      message: `Please confirm that your information is correct. Your name and date of birth should be exactly as it appears on your photo ID. This information will be on your result report. Also confirm your phone number and email are valid to receive your result notification.`,
      childrenElement: () => <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <div style={{ margin: "5px 0px" }}>First Name:<span className="Status_name">{Fname}</span></div>
        <div style={{ margin: "5px 0px" }}>Last Name:<span className="Status_name">{Lname}</span></div>
        <div style={{ margin: "5px 0px" }}>DOB (MMM DD, YYYY):<span className="Status_name">{moment(DOB).format("MMM DD, YYYY")}</span></div>
        <div className="modalPhoneNumber" style={{ margin: "-3px 0px", display: "flex", alignItems: "center" }}><div>Phone:</div>
          <PhoneInput
            country={"us"}
            disabled={true}
            required
            name="mobileNumber"
            disableDropdown={true}
            value={Phone}
          /></div>
        <div style={{ margin: "5px 0px" }}>Email:<span className="Status_name">{Email ? Email : "N/A"}</span></div>
        <div style={{ margin: "5px 0px" }}>By clicking confirm, you agree to receiving a text/email notification with your appointment confirmation details.</div>
      </div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun()
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = () => {
    var finaldateDob = moment(selectedDate).format("yyyy-MM-DD");
    if (selectedDateSymptom != null) {
      var finalselectedDateSymptom =
        moment(selectedDateSymptom).format("yyyy-MM-DD");
    }
    let splitstate = state.split(",");
    console.log("redtest", state, splitstate);
    if (vaccinated == "Y" && lastDoseDate !== "") {
      var finallastDoseDate = lastDoseDate
        ? moment(lastDoseDate).format("yyyy-MM-DD")
        : null;
    }
    let testCreatedIn="";
    if(window.location.href.includes("/schooltesting")){
      if(process.env.REACT_APP_LAB_ID==="15"){
        testCreatedIn = "EPI-STT-school-testing"
      }
      else if(process.env.REACT_APP_LAB_ID==="14"){
        testCreatedIn = "EPI-STX-school-testing"
      }
      else if(process.env.REACT_APP_LAB_ID==="31"){
        testCreatedIn = "EPI-STJ-school-testing"
      }
    }
    else if(window.location.href.includes("/special-event")){
      if(process.env.REACT_APP_LAB_ID==="15"){
        testCreatedIn = "EPI-STT-special-event"
      }
      else if(process.env.REACT_APP_LAB_ID==="14"){
        testCreatedIn = "EPI-STX-special-event"
      }
    }
    else{
      if(process.env.REACT_APP_LAB_ID==="15"){
        testCreatedIn = "EPI-STT-testing"
      }
      else if(process.env.REACT_APP_LAB_ID==="14"){
        testCreatedIn = "EPI-STX-testing"
      }
      else if(process.env.REACT_APP_LAB_ID==="31"){
        testCreatedIn = "EPI-STJ-testing"
      }
    }
    let data = {
      ...formstate,

      //misc
      patientId: 0,
      fromWeb: true,
      createdIn: testCreatedIn,

      //first accordion
      state: splitstate[0],
      firstName: formstate.firstName.trim(),
      lastName: formstate.lastName.trim(),
      mobileNumber: "+" + newnumber,
      email: stateCheckedNA === true ? "N/A" : formstate.email.trim(),
      addressOne: formstate.addressOne.trim(),
      country: country,
      county: county === "" ? formstate.city.trim() : county,
      city:
        formstate.city.trim() === "" ? county.trim() : formstate.city.trim(),
      phoneCode: "+" + dialCode,
      middleName:
        formstate.middleName == "" ? "" : formstate.middleName.trim(),
      addressTwo:
        formstate.addressTwo == "" ? "" : formstate.addressTwo.trim(),
      immagirationStatus: immigrationStatus ? immigrationStatus : null,

      //second accordion
      dob: finaldateDob === undefined ? null : finaldateDob,
      gender: gender,
      ethnicity: ethnicity == "" ? null : ethnicity,
      prioritizedGroup: member,
      race: racedSelect == "" ? null : racedSelect,
      sendMessageToMobile: sendMessageToMobileCheck === true ? true : false,

      //third accordion
      symptomaticDate:
        finalselectedDateSymptom === undefined
          ? null
          : finalselectedDateSymptom,

      //uncomment
      pregnant: gender == "Female" ? pregnancy : "U",
      covid19VaccineType: Covid19vaccineType ? Covid19vaccineType : "",
      noOfDosesReceived: doseCount == "" ? "" : doseCount,
      lastDoseDate:
        finallastDoseDate === undefined ? null : finallastDoseDate,

      // pregnant: gender == "Female" ? pregnancy : null,
      symptoms:
        Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
          .length == 0
          ? null
          : Object.keys(stateChecked)
              .filter((e) => stateChecked[e] === true)
              .map((index) =>
                index.replaceAll("_", " ").replaceAll("Fever", "Fever>100.4")
              ),

      symptomatic: symptomatic,
      receivedCovid19Vaccine: vaccinated,
      typeOfTest: testType ? testType : null,
      sexAtBirth: birthgender ? birthgender : null,
      previousHIVTest: hivtest ? hivtest : null,
      heardOfPrep: HeardofPrep ? HeardofPrep : null,
      currentlyOnPrep: CurrentonPrep ? CurrentonPrep : null,
      prepInPast: PrepinLast ? PrepinLast : null,
      sexWithMale: sexWithMale ? sexWithMale : null,
      sexWithFemale: sexWithFemale ? sexWithFemale : null,
      sexWithTransgender: sexWithTransgender ? sexWithTransgender : null,
      injectedDrugs: injectionDrugUse ? injectionDrugUse : null,
      authorizationName: authName ? authName : null,
      consentName: consentName ? consentName : null,
    };

    setIsLoaded(true);
    //api for posting form
    let apiUrl = "";
    if (
      process.env.REACT_APP_LAB_ID === "16" ||
      process.env.REACT_APP_LAB_ID === "17"
    ) {
      apiUrl = "/api/web/cdd/patient/add?labId=";
    } else {
      apiUrl = "/api/web/patient/add?labId=";
    }
    axios
      .post(
        process.env.REACT_APP_API_ENDPOINT +
          apiUrl +
          process.env.REACT_APP_LAB_ID,
        data,
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 200 && res.data.status == "Success") {
          setOpen(true);
          setIsLoaded(false);
          setConfirmationCode(res.data.info.confirmationCode);
          setQrcode(res.data.info.qrCodeUrl);
          setpdfdownload(res.data.info.qrCodePdfUrl);
          setlabSiteName(res.data.info.labSiteName);
          setdob(moment(res.data.info.dob).format("MM/DD/yyyy"));
          console.log(res.data.info);
          setdate(res.data.info.createdDate);
          settime(res.data.info.createdTime);
        } else if (res.data.errorCode == 400) {
          setIsLoaded(false);
          alert(res.data.errorMessage);
        }
      });
  }

  const cancelfun = () => {
    console.log("cancel clicked")
  }


  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="accordionHolder">
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="mainer"
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography ref={myRef}>
                    General Information
                    <FormHelperText style={{ color: "red" }}>
                      <span>{errorTestType !== "" ? errorTestType : ""}</span>
                      <span>{errorFirstData !== "" ? errorFirstData : ""}</span>

                      <span> {errorLastData ? errorLastData : ""}</span>
                      <span> {errorMobileData ? errorMobileData : ""}</span>
                      <span> {errorEmailData ? errorEmailData : ""}</span>
                      <span> {errorAddress ? errorAddress : ""}</span>
                      <span> {errorCounty ? errorCounty : ""}</span>
                      <span> {errorCity ? errorCity : ""}</span>
                      <span> {errorCountry ? errorCountry : ""}</span>
                      <span> {errorZip ? errorZip : ""}</span>
                      <span> {errorState ? errorState : ""}</span>
                      <span>
                        {" "}
                        {immigrationStatusError ? immigrationStatusError : ""}
                      </span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  {process.env.REACT_APP_LAB_ID === "16" ||
                  process.env.REACT_APP_LAB_ID === "17" ? (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Today I would like to be tested for: *
                      </FormLabel>
                      <RadioGroup
                        aria-label="testType"
                        name="testType"
                        value={testType}
                        onChange={handletestType}
                      >
                        <FormControlLabel
                          value="COVID"
                          control={<Radio />}
                          label="COVID Only"
                        />
                        <FormControlLabel
                          value="HIV"
                          control={<Radio />}
                          label="HIV Only"
                        />
                        <FormControlLabel
                          value="Both"
                          control={<Radio />}
                          label="Both COVID and HIV"
                        />
                      </RadioGroup>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic1"
                      label="First Name "
                      autoComplete="new-password"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="firstName"
                      value={formstate.firstName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic2"
                      label="Middle Initial"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="middleName"
                      autoComplete="new-password"
                      value={formstate.middleName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic3"
                      label="Last Name"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="lastName"
                      autoComplete="new-password"
                      value={formstate.lastName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Please share your contact information. Your email and
                      phone will also be where you will receive an invitation to
                      view your test results.
                    </FormLabel>
                  </Grid>

                  {/* {process.env.REACT_APP_LAB_ID === "71" ||
                  process.env.REACT_APP_LAB_ID === "72" ||
                  process.env.REACT_APP_LAB_ID === "73" ||
                  process.env.REACT_APP_LAB_ID === "74" ||
                  process.env.REACT_APP_LAB_ID === "75" ||
                  process.env.REACT_APP_LAB_ID === "76" ||
                  process.env.REACT_APP_LAB_ID === "80" ||
                  process.env.REACT_APP_LAB_ID === "81" ||
                  process.env.REACT_APP_LAB_ID === "82" ||
                  process.env.REACT_APP_LAB_ID === "112" ? (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={localMobileCheck}
                            onChange={handlelocalMobileCheck}
                            name="N/A"
                            value="N/A"
                          />
                        }
                        label="I do not have a local phone number."
                        // style={{ marginTop: "13px" }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )} */}

                  <Grid item xs={12} md={7} sm={12}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ paddingBottom: "0.3em" }}
                    >
                      Mobile Number *
                    </InputLabel>
                    <PhoneInput
                      country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={mobileNumberChange}
                      value={newnumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendMessageToMobileCheck}
                          onChange={handleChangeMobileCheck}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="This number can receive text messages"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={7} sm={12}>
                    <TextField
                      id="standard-basic4"
                      label="Email"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="email"
                      autoComplete="new-password"
                      value={stateCheckedNA === true ? "N/A" : formstate.email}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={stateChecked.Shortness_of_breath}
                          onChange={handleChangeNA}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="N/A - Not Applicable"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic5"
                      label="Address 1"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="addressOne"
                      autoComplete="new-password"
                      value={formstate.addressOne}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic6"
                      label="Address 2"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="addressTwo"
                      autoComplete="new-password"
                      value={formstate.addressTwo}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Country*</InputLabel>
                      <Select
                        name="country"
                        value={country}
                        onChange={handleChangeCountry}
                        required
                      >
                        {countryList.map((item) => (
                          <MenuItem value={item.countryCode}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        State/Territory *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="state"
                        value={state}
                        onChange={handleChangeState}
                        required
                      >
                        {stateSelect.map((item) => (
                          <MenuItem value={item[1].stateCode + "," + item[0]}>
                            {item[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic8"
                      label="Zipcode/Postal Code"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={handleChangeForm}
                      name="zipCode"
                      autoComplete="new-password"
                      value={formstate.zipCode}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  {countyTemp.length ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          County/District
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label11"
                          id="demo-simple-select"
                          onChange={countyChange}
                          value={county}
                        >
                          {countyTemp !== null && countyTemp !== undefined
                            ? countyTemp.map((item) => (
                                <MenuItem value={item[0]}>{item[0]}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic8"
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        label="City/Estate*"
                        variant="standard"
                        name="city"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        value={formstate.city}
                        onChange={handleChangeForm}
                      />
                    </Grid>
                  )}

                  {process.env.REACT_APP_LAB_ID === "71" ||
                  process.env.REACT_APP_LAB_ID === "72" ||
                  process.env.REACT_APP_LAB_ID === "73" ||
                  process.env.REACT_APP_LAB_ID === "74" ||
                  process.env.REACT_APP_LAB_ID === "75" ||
                  process.env.REACT_APP_LAB_ID === "76" ||
                  process.env.REACT_APP_LAB_ID === "80" ||
                  process.env.REACT_APP_LAB_ID === "81" ||
                  process.env.REACT_APP_LAB_ID === "82" ||
                  process.env.REACT_APP_LAB_ID === "114" ||
                  process.env.REACT_APP_LAB_ID === "115" ||
                  process.env.REACT_APP_LAB_ID === "118" ||
                  process.env.REACT_APP_LAB_ID === "127" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          What is your immigration status? *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label11"
                          id="demo-simple-select"
                          onChange={immigrationStatusChange}
                          value={immigrationStatus}
                        >
                          <MenuItem value={"BVIslander/Belonger"}>
                            BVIslander/Belonger
                          </MenuItem>
                          <MenuItem value={"BVI Resident"}>
                            BVI Resident
                          </MenuItem>
                          <MenuItem value={"Tourist"}>Tourist</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} md={12} sm={12}>
                    <div
                      className={active1 === true ? "buttonNext" : "buttonNext"}
                    >
                      <button onClick={moveNext}>Next</button>
                    </div>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    Additional Demographics
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorOccupation ? errorOccupation : ""}</span>
                      <span> {errorRace ? errorRace : ""}</span>
                      <span> {errorGender ? errorGender : ""}</span>
                      <span> {errorBirthGender ? errorBirthGender : ""}</span>

                      <span> {errorDateofbirth ? errorDateofbirth : ""}</span>
                      <span> {errorMember ? errorMember : ""}</span>
                      <span> {errorEthnicity ? errorEthnicity : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={6} sm={12}>
                      <KeyboardDatePicker
                        clearable
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format="MM/dd/yyyy"
                        label={
                          <Typography variant="inherit" component="span">
                            {" "}
                            Date of Birth{" "}
                            <span className="adjs">(MM/DD/YYYY) </span>
                          </Typography>
                        }
                        maxDate={new Date()}
                        required
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Race *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={racedSelect}
                        onChange={handleChangeRace}
                        name="race"
                      >
                        {raced.map((index) => (
                          <MenuItem value={index.raceName}>
                            {index.raceName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {process.env.REACT_APP_LAB_ID === "16" ||
                  process.env.REACT_APP_LAB_ID === "17" ? (
                    <>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Current Gender *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            onChange={handleChangeGender}
                            required
                          >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Transgender Male to Female"}>
                              Transgender Male to Female
                            </MenuItem>
                            <MenuItem value={"Transgender Female to Male"}>
                              Transgender Female to Male
                            </MenuItem>
                            <MenuItem value={"Transgender Unspecified"}>
                              Transgender Unspecified
                            </MenuItem>
                            <MenuItem value={"Declined to Answer"}>
                              Declined to Answer
                            </MenuItem>
                            <MenuItem value={"Another Gender"}>
                              Another Gender
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Sex at birth *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={birthgender}
                            onChange={handleChangeBirthGender}
                            required
                          >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Intersex"}>Intersex</MenuItem>
                            <MenuItem value={"Declined to Answer"}>
                              Declined to Answer
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : process.env.REACT_APP_LAB_ID === "71" ||
                    process.env.REACT_APP_LAB_ID === "72" ||
                    process.env.REACT_APP_LAB_ID === "73" ||
                    process.env.REACT_APP_LAB_ID === "74" ||
                    process.env.REACT_APP_LAB_ID === "75" ||
                    process.env.REACT_APP_LAB_ID === "76" ||
                    process.env.REACT_APP_LAB_ID === "80" ||
                    process.env.REACT_APP_LAB_ID === "81" ||
                    process.env.REACT_APP_LAB_ID === "82" ||
                    process.env.REACT_APP_LAB_ID === "114" ||
                    process.env.REACT_APP_LAB_ID === "115" ||
                    process.env.REACT_APP_LAB_ID === "118" ||
                    process.env.REACT_APP_LAB_ID === "127" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Gender *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={gender}
                          onChange={handleChangeGender}
                          required
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                          {/* <MenuItem value={"Unknown"}>Unknown</MenuItem> */}
                          <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Gender *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={gender}
                          onChange={handleChangeGender}
                          required
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                          {/* <MenuItem value={"Unknown"}>Unknown</MenuItem> */}
                          <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} sm={12} className="radioAwesome">
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Ethnicity *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ethnicity}
                        onChange={handleChangeEthnicity}
                        name="ethnicity"
                      >
                        {RaceEthinicity.map((index) => (
                          <MenuItem value={index.ethinicityName}>
                            {index.ethinicityName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Is the patient a member of one of these groups? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="member"
                      name="member"
                      value={member}
                      onChange={handleChangeMember}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="First response/Healthcare"
                          control={<Radio />}
                          label="First responder or Healthcare worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Congregate facility"
                          control={<Radio />}
                          label="Congregate facility worker or resident"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Hospitality/Transportation"
                          control={<Radio />}
                          label="Hospitality or Transportation worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="In-person school/daycare"
                          control={<Radio />}
                          label="In-person school/daycare student or worker"
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not reported"
                          control={<Radio />}
                          label="Not reported"
                        />
                      </Grid> */}

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not part of any listed groups"
                          control={<Radio />}
                          label="Not part of any listed groups"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Others"
                          control={<Radio />}
                          label="Other priority group (Please indicate below)"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {member ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic10"
                        label="Occupation/Affiliation"
                        style={{ width: "100%" }}
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        required
                        onChange={handleChangeForm}
                        name="occupation"
                        autoComplete="new-password"
                        value={formstate.occupation}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <div
                    className={active2 === true ? "buttonNext" : "buttonNext"}
                  >
                    <button onClick={moveNext1}>Next</button>
                  </div>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    Recent Health Updates
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorStateChecked ? errorStateChecked : ""}</span>
                      <span> {errorSymptomDate ? errorSymptomDate : ""}</span>
                      <span> {errorSymptoms ? errorSymptoms : ""}</span>
                      <span> {errorSymptomatic ? errorSymptomatic : ""}</span>
                      <span> {errorVaccinated ? errorVaccinated : ""}</span>

                      <span> {errorhivtest ? errorhivtest : ""}</span>
                      <span> {errorHeardofPrep ? errorHeardofPrep : ""}</span>
                      <span>
                        {" "}
                        {errorCurrentonPrep ? errorCurrentonPrep : ""}
                      </span>
                      <span> {errorPrepinLast ? errorPrepinLast : ""}</span>
                      <span> {errorsexWithMale ? errorsexWithMale : ""}</span>
                      <span>
                        {" "}
                        {errorsexWithFemale ? errorsexWithFemale : ""}
                      </span>
                      <span>
                        {" "}
                        {errorsexWithTransgender ? errorsexWithTransgender : ""}
                      </span>
                      <span>
                        {" "}
                        {errorinjectionDrugUse ? errorinjectionDrugUse : ""}
                      </span>

                      <span>
                        {Covid19vaccineTypeError ? Covid19vaccineTypeError : ""}
                      </span>
                      <span>{doseCountError ? doseCountError : ""}</span>
                      <span>{lastDoseDateError ? lastDoseDateError : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                {gender == "Female" ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Pregnancy Status
                    </FormLabel>
                    <RadioGroup
                      aria-label="pregnancy"
                      name="pregnancy"
                      value={pregnancy}
                      onChange={handleChangeFormPregnancy}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />

                      <FormControlLabel
                        value="U"
                        control={<Radio />}
                        label="Unknown"
                      />
                    </RadioGroup>
                  </Grid>
                ) : null}

                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <Grid item xs={12} md={12} sm={12}>
                      <FormLabel component="legend" className="paddingAdj">
                        Have you been experiencing any Covid-19 symptoms? *
                      </FormLabel>
                      <RadioGroup
                        aria-label="symtomatic"
                        name="symtomatic"
                        value={symptomatic}
                        onChange={handleSymptomatic}
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                    {symptomatic === "Y" ? (
                      <FormGroup className="adjCheck">
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Shortness_of_breath}
                                onChange={handleChangeCheck}
                                name="Shortness_of_breath"
                                value="Shortness of breath"
                              />
                            }
                            label="Shortness of breath"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Cough}
                                onChange={handleChangeCheck}
                                name="Cough"
                                value="Cough"
                              />
                            }
                            label="Cough"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Chills}
                                onChange={handleChangeCheck}
                                name="Chills"
                                value="Chills"
                              />
                            }
                            label="Chills"
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Fever}
                                onChange={handleChangeCheck}
                                name="Fever"
                                value="Fever >100.4"
                              />
                            }
                            label="Fever >100.4"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Difficulty_breathing}
                                onChange={handleChangeCheck}
                                name="Difficulty_breathing"
                                value="Difficulty breathing"
                              />
                            }
                            label="Difficulty breathing"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Headache}
                                onChange={handleChangeCheck}
                                name="Headache"
                                value="Headache"
                              />
                            }
                            label="Headache"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Fatigue}
                                onChange={handleChangeCheck}
                                name="Fatigue"
                                value="Fatigue"
                              />
                            }
                            label="Fatigue"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Diarrhea}
                                onChange={handleChangeCheck}
                                name="Diarrhea"
                                value="Diarrhea"
                              />
                            }
                            label="Diarrhea"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Vomiting}
                                onChange={handleChangeCheck}
                                name="Vomiting"
                                value="Vomiting"
                              />
                            }
                            label="Vomiting"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Sore_Throat}
                                onChange={handleChangeCheck}
                                name="Sore_Throat"
                                value="Sore Throat"
                              />
                            }
                            label="Sore Throat"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Nausea}
                                onChange={handleChangeCheck}
                                name="Nausea"
                                value="Nausea"
                              />
                            }
                            label="Nausea"
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Loss_of_taste}
                                onChange={handleChangeCheck}
                                name="Loss_of_taste"
                                value="Loss of taste"
                              />
                            }
                            label={
                              <Typography
                                variant="inherit"
                                component="span"
                                style={{ paddingTop: "1em" }}
                              >
                                Loss of taste <br /> and / or smell{" "}
                              </Typography>
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={stateChecked.Body_and_or_muscle_aches}
                                onChange={handleChangeCheck}
                                name="Body_and_or_muscle_aches"
                                value="Body and/or muscle aches"
                              />
                            }
                            label="Body and/or muscle aches"
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  stateChecked.Congestion_and_or_runny_nose
                                }
                                onChange={handleChangeCheck}
                                name="Congestion_and_or_runny_nose"
                                value="Congestion and/or runny nose"
                              />
                            }
                            label="Congestion and/or runny nose"
                          />
                        </Grid>
                      </FormGroup>
                    ) : null}
                  </FormControl>
                </Grid>

                {symptomatic === "Y" &&
                Object.keys(stateChecked).filter(
                  (e) => stateChecked[e] === true
                ).length > 0 &&
                stateChecked !== undefined ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sm={12}
                      style={{ marginTop: "1em" }}
                    >
                      <KeyboardDatePicker
                        clearable
                        value={selectedDateSymptom}
                        onChange={(date) => handleDateChangeSymptom(date)}
                        format="MM/dd/yyyy"
                        label="Symptom start date"
                        maxDate={new Date()}
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : null}

                <Grid item xs={12} md={12} sm={12}>
                  <FormLabel component="legend" className="paddingAdj">
                    Have you received the Covid-19 vaccine? *
                  </FormLabel>
                  <RadioGroup
                    aria-label="symtomatic"
                    name="symtomatic"
                    value={vaccinated}
                    onChange={handleVaccinated}
                  >
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="Yes, I received the vaccine"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="No, I have not yet received the vaccine"
                    />
                  </RadioGroup>
                </Grid>

                {vaccinated === "Y" &&
                  (process.env.REACT_APP_LAB_ID === "71" ||
                    process.env.REACT_APP_LAB_ID === "72" ||
                    process.env.REACT_APP_LAB_ID === "73" ||
                    process.env.REACT_APP_LAB_ID === "74" ||
                    process.env.REACT_APP_LAB_ID === "75" ||
                    process.env.REACT_APP_LAB_ID === "76" ||
                    process.env.REACT_APP_LAB_ID === "80" ||
                    process.env.REACT_APP_LAB_ID === "81" ||
                    process.env.REACT_APP_LAB_ID === "82" ||
                    process.env.REACT_APP_LAB_ID === "114" ||
                    process.env.REACT_APP_LAB_ID === "115" ||
                    process.env.REACT_APP_LAB_ID === "118" ||
                    process.env.REACT_APP_LAB_ID === "127") && (
                    <>
                      <Grid
                        style={{ marginTop: "3px" }}
                        container
                        spacing={3}
                        item
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Which COVID-19 vaccine type did you receive? *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label11"
                              id="demo-simple-select"
                              onChange={handleCovid19vaccineType}
                              value={Covid19vaccineType}
                            >
                              <MenuItem value={"Astra-Zeneca"}>
                                Astra-Zeneca
                              </MenuItem>
                              <MenuItem value={"Janssen"}>Janssen</MenuItem>
                              <MenuItem value={"Moderna"}>Moderna</MenuItem>
                              <MenuItem value={"Pfizer-BioNTech"}>
                                Pfizer-BioNTech
                              </MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {(Covid19vaccineType == "Astra-Zeneca" ||
                          Covid19vaccineType == "Moderna" ||
                          Covid19vaccineType == "Pfizer-BioNTech" ||
                          Covid19vaccineType == "Other") && (
                          <Grid item xs={12} md={6} sm={12}>
                            <FormLabel component="legend">
                              How many doses have you received? *
                            </FormLabel>
                            <RadioGroup
                              aria-label="dosecount"
                              name="dosecount"
                              value={doseCount}
                              onChange={handleChangedoseCount}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="One"
                              />
                              <FormControlLabel
                                value="2+"
                                control={<Radio />}
                                label="Two +"
                              />
                            </RadioGroup>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} item xs={12} md={12} sm={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid item xs={12} md={6} sm={12}>
                            <KeyboardDatePicker
                              clearable
                              value={lastDoseDate}
                              onChange={(date) => handlelastDoseDate(date)}
                              format="dd/MM/yyyy"
                              label={
                                <Typography variant="inherit" component="span">
                                  {" "}
                                  When was your last dose date?{" "}
                                  <span className="adjs">(DD/MM/YYYY) </span>
                                </Typography>
                              }
                              maxDate={new Date()}
                              required
                              autoOk
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </>
                  )}
                {(process.env.REACT_APP_LAB_ID === "16" ||
                  process.env.REACT_APP_LAB_ID === "17") &&
                (testType === "HIV" || testType === "Both") ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Previous HIV Test? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="hivtest"
                          name="hivtest"
                          value={hivtest}
                          onChange={handlehivtest}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Ever heard of Prep? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="HeardofPrep"
                          name="HeardofPrep"
                          value={HeardofPrep}
                          onChange={handleHeardofPrep}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Currently on Prep? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="CurrentonPrep"
                          name="CurrentonPrep"
                          value={CurrentonPrep}
                          onChange={handleCurrentonPrep}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Have you used Prep in the past 12 months? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="PrepinLast"
                          name="PrepinLast"
                          value={PrepinLast}
                          onChange={handlePrepinLast}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          In the past 5 years, have you had sex with a male? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="sexWithMale"
                          name="sexWithMale"
                          value={sexWithMale}
                          onChange={handlesexWithMale}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          In the past 5 years, have you had sex with a female? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="sexWithFemale"
                          name="sexWithFemale"
                          value={sexWithFemale}
                          onChange={handlesexWithFemale}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          In the past 5 years, have you had sex with a
                          transgender person? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="sexWithTransgender"
                          name="sexWithTransgender"
                          value={sexWithTransgender}
                          onChange={handlesexWithTransgender}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          In the past 5 years, have you injected any drugs or
                          substances? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="injectionDrugUse"
                          name="injectionDrugUse"
                          value={injectionDrugUse}
                          onChange={handleinjectionDrugUse}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {(process.env.REACT_APP_LAB_ID === "16" ||
            process.env.REACT_APP_LAB_ID === "17") &&
          (testType === "HIV" || testType === "Both") ? (
            <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
              <Grid item xs={12} md={6} sm={12}>
                <Typography className="AuthpaddingAdj">
                  Authorization for Release of Protected Health Information:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-basic1"
                  InputProps={{
                    className: classes.input,
                  }}
                  label="Enter name"
                  autoComplete="new-password"
                  onChange={handleAuthNameChange}
                  name="authName"
                  value={authName}
                  inputProps={{ maxLength: 40 }}
                  className="AuthTxtpaddingAdj"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography className="AuthTxtAdj">
                  1. I hereby voluntarily authorize VIRGIN ISLANDS DEPARTMENT OF
                  HEALTH (COMMUNICABLE DISEASE DIVISION) to disclose my HIV test
                  results via phone and/or email.
                </Typography>
                <Typography className="AuthTxtAdj">
                  2. The purpose for this disclosure is for HIV TESTING RESULTS.
                </Typography>
                <Typography className="AuthTxtAdj">
                  3. The information to be disclosed is:
                </Typography>
                <Typography className="AuthTxtAdj">
                  Only medical information from the date this form was
                  electronically submitted.
                </Typography>
                <Typography className="AuthTxtAdj">
                  This form indicates you would like the following sensitive
                  information disclosed:
                </Typography>
                <Typography className="AuthTxtAdj">
                  HIV/ AIDS- related Treatment
                </Typography>
                <Typography className="AuthTxtAdj">
                  4. This authorization shall become effective immediately and
                  shall remain in effect until one year from the date this your
                  signature is electronically submitted.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that I may revoke this authorization in writing
                  at any time prior to the release of information from VIDOH
                  (CDD), and that revocation will not affect any action taken in
                  reliance on this authorization before the written revocation
                  was received.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that my eligibility for benefits, treatment or
                  payment is not conditioned upon my provision of this
                  authorization.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that information disclosed by this authorization
                  may be subject to re-disclosure by the recipient and no longer
                  protected by the Health Insurance Portability and
                  Accountability Act.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that by typing my legal name below I am signing
                  this form electronically and in agreement with this and the
                  above statements.
                </Typography>
                <Typography className="AuthTxtAdj">
                  Type your legal name above to authorize us to send your HIV
                  results by phone and/or email. If you do not authorize us to
                  do so, leave blank.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <Typography
                  className="AuthpaddingAdj"
                  style={{ paddingTop: "10px" }}
                >
                  Consent of HIV Counseling and Testing:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  id="standard-basic1"
                  InputProps={{
                    className: classes.input,
                  }}
                  label="Enter name"
                  onChange={handleConsentNameChange}
                  name="consentName"
                  value={consentName}
                  inputProps={{ maxLength: 40 }}
                  autoComplete="new-password"
                  className="AuthTxtpaddingAdj"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography className="AuthTxtAdj">
                  I hereby authorize the Communicable Disease Division of the
                  Department of Health to provide HIV counseling and to obtain
                  blood or oral fluids to test for the Human Immunodeficiency
                  Virus (HIV) antibodies.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that my records are protected and under the
                  federal regulation governing "confidentiality" and cannot be
                  disclosed without my prior written consent unless otherwise
                  provided for in the regulations. I also understand that I may
                  revoke this consent any time except to the extent and action
                  has been taken in reliance on it, and that in even this
                  consent expires automatically after the date recorded in the
                  electronic timestamp that is submitted upon completion of this
                  form.
                </Typography>
                <Typography className="AuthTxtAdj">
                  I understand that by typing my legal name below I am signing
                  this form electronically and in agreement with this and the
                  above statements.
                </Typography>
                <Typography className="AuthTxtAdj">
                  Type your legal name above to consent to HIV testing and
                  counseling.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <div className="submit">
            <button className="btn" onClick={formSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}
