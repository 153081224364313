import React from "react";
import footerlogo from "../assets/applogo.png";
import footerlogoapp from "../assets/apptext.png";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="footerHolder">
          <div className="birdlogo"></div>

          <div className="copyRight">
            <span> Copyright &copy;{new Date().getFullYear()}</span>
            {"."}

            <span className="paddingADJF">
              Powered by{" "}
              <img className="footerimgadj widthLog" src={footerlogo} />
            </span>
            <span>
              <img className="footerimgadj" src={footerlogoapp} />
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
