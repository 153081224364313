import React from 'react';
import bannerImage from "../assets/bannerImage.png"


export default function RegisterBanner() {
    return (
        <>
        <div className="bannerHolder">

            <div className="bannerContent">
                <h1>Patient Registration</h1>
                <p>Please provide the following information</p>
            </div>
            <div className="bannerImage">
                <img src={bannerImage} alt="banner" className="bannerRight"/>
            </div>
        </div>
            
        </>
    )
}
