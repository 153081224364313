import React from "react";
import logo from "../assets/popper.png";
import pafford from "../assets/pafford.jpg";
import British from "../assets/BVI-Logo.jpeg";
import Airport from "../assets/Airpots.png";
import Jetty from "../assets/Ports.png";
import VIPD from "../assets/VIPD Logo.gif";
import Trianglelab from "../assets/trianglelab-logo.png";
import AeroMD from "../assets/AeroMD.png"
import USVI from "../assets/USVI.png"
import { Helmet } from "react-helmet";

export default function Header() {
  let labTitle = "";

  if (
    window.location.pathname.includes("/event-testing") &&
    process.env.REACT_APP_LAB_ID === "15"
  ) {
    labTitle = " Event Testing";
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {process.env.REACT_APP_LAB_NAME} - Patient Registration Powered By
          Hummingbird Health{" "}
        </title>
        <meta
          name="description"
          content={
            process.env.REACT_APP_LAB_NAME +
            "- Patient Registration Powered By Hummingbird Health"
          }
        />
      </Helmet>

      <div className="headerHolder">
        <div className="lefImage">
          <div>
            {process.env.REACT_APP_LAB_ID === "70" ||
              process.env.REACT_APP_LAB_ID === "111" ? (
              <img
                src={pafford}
                alt="humming bird logo"
                style={{ maxHeight: "40px" }}
              />
            ) : process.env.REACT_APP_LAB_ID === "71" ? (
              <img src={British} alt="humming bird logo" />
            ) : process.env.REACT_APP_LAB_ID === "110" ? (
              <img src={Trianglelab} alt="triangle laboratory" />
            ) : process.env.REACT_APP_LAB_ID === "79" ||
              process.env.REACT_APP_LAB_ID === "87" ? (
              <img src={VIPD} alt="humming bird logo" />
            )
              : process.env.REACT_APP_LAB_ID === "138" ? (
                <img src={AeroMD} alt="humming bird logo" />
              )
                : process.env.REACT_APP_LAB_ID === "140" ? (
                  <img src={USVI} alt="humming bird logo" />
                )
                  : process.env.REACT_APP_LAB_ID === "72" ||
                    process.env.REACT_APP_LAB_ID === "76" ||
                    process.env.REACT_APP_LAB_ID === "80" ||
                    process.env.REACT_APP_LAB_ID === "81" ||
                    process.env.REACT_APP_LAB_ID === "82" ? (
                    ""
                  ) : process.env.REACT_APP_LAB_ID === "73" ? (
                    <img src={Airport} className="roadImg1" alt="humming bird logo" />
                  ) : process.env.REACT_APP_LAB_ID === "74" ||
                    process.env.REACT_APP_LAB_ID === "75" ? (
                    <img src={Jetty} className="roadImg" alt="humming bird logo" />
                  ) : process.env.REACT_APP_LAB_ID === "8" ||
                    process.env.REACT_APP_LAB_ID === "88" ||
                    process.env.REACT_APP_LAB_ID === "89" ||
                    process.env.REACT_APP_LAB_ID === "90" ||
                    process.env.REACT_APP_LAB_ID === "91" ||
                    process.env.REACT_APP_LAB_ID === "92" ||
                    process.env.REACT_APP_LAB_ID === "93" ||
                    process.env.REACT_APP_LAB_ID === "94" ||
                    process.env.REACT_APP_LAB_ID === "95" ||
                    process.env.REACT_APP_LAB_ID === "96" ||
                    process.env.REACT_APP_LAB_ID === "97" ||
                    process.env.REACT_APP_LAB_ID === "98" ||
                    process.env.REACT_APP_LAB_ID === "99" ||
                    process.env.REACT_APP_LAB_ID === "100" ||
                    process.env.REACT_APP_LAB_ID === "101" ||
                    process.env.REACT_APP_LAB_ID === "102" ||
                    process.env.REACT_APP_LAB_ID === "103" ||
                    process.env.REACT_APP_LAB_ID === "104" ||
                    process.env.REACT_APP_LAB_ID === "105" ||
                    process.env.REACT_APP_LAB_ID === "106" ||
                    process.env.REACT_APP_LAB_ID === "107" ||
                    process.env.REACT_APP_LAB_ID === "108" ||
                    process.env.REACT_APP_LAB_ID === "109" ||
                    process.env.REACT_APP_LAB_ID === "112" ||
                    process.env.REACT_APP_LAB_ID === "113" ||
                    process.env.REACT_APP_LAB_ID === "114" ||
                    process.env.REACT_APP_LAB_ID === "115" ||
                    process.env.REACT_APP_LAB_ID === "116" ||
                    process.env.REACT_APP_LAB_ID === "118" ||
                    process.env.REACT_APP_LAB_ID === "16" ||
                    process.env.REACT_APP_LAB_ID === "17" ||
                    process.env.REACT_APP_LAB_ID === "127" ||
                    process.env.REACT_APP_LAB_ID === "139" ||
                    process.env.REACT_APP_LAB_ID === "142" ||
                    process.env.REACT_APP_LAB_ID === "143" ||
                    process.env.REACT_APP_LAB_ID === "144" ||
                    process.env.REACT_APP_LAB_ID === "158" ||
                    process.env.REACT_APP_LAB_ID === "159" ||
                    process.env.REACT_APP_LAB_ID === "160" ||
                    process.env.REACT_APP_LAB_ID === "187" ||
                    process.env.REACT_APP_LAB_ID === "189" ? (
                    ""
                  ) : (
                    <img src={logo} alt="humming bird logo" />
                  )}
          </div>

          <div>
            {/* <h1> DOH Epidemiology Division (STT) </h1> */}
            {/* <h1> DOH Epidemiology Division (STX) </h1> */}
            {process.env.REACT_APP_LAB_NAME === "Pafford EMS" ||
              process.env.REACT_APP_LAB_NAME === "AeroMD" ? (
              ""
            ) : (
              <h1>
                {" "}
                {process.env.REACT_APP_HEADER_NAME ?
                 process.env.REACT_APP_HEADER_NAME
                 : process.env.REACT_APP_LAB_NAME + " " + labTitle}
                
              </h1>
            )}
          </div>
        </div>

        <div className="rightImage">{/* <img src={}/> */}</div>
      </div>
    </>
  );
}
