import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import Form from "./Components/Form.js";
import RegisterBanner from "./Components/RegisterBanner.js";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";

function App() {
  return (
    <>
      <div className="mainHolder">
        <Header />
        <RegisterBanner />
        <Router>
          <Routes>
            <Route path="/" exact element={<Form />} />
            <Route path="/event-testing" exact element={<Form />} />
            <Route path="/schooltesting" exact element={<Form />} />
            <Route path="/special-event" exact element={<Form />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
